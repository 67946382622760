import React from "react";
import hero from "../../static/hero.jpg";
const Hero = () => {
  return (
    <section className="section">
      <div className="hero">
        <div className="hero__image">
          <div className="visual visual--16x9">
            <img src={hero} alt="" />
          </div>
        </div>
        <div className="hero__body">
          <h1 className="hero__title">Kinesitherapiepraktijk Hagers</h1>
        </div>
      </div>
    </section>
  );
};

export default Hero;
