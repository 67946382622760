import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import InfoBlock from "../components/InfoBlock";
import InfoBlockLarge from "../components/InfoBlockLarge";
import image1 from "../../static/image1.jpg";
import image2 from "../../static/image2.jpg";
import image3 from "../../static/image3.jpg";
import image4 from "../../static/image4.jpg";
import image5 from "../../static/image5.jpg";
import image7 from "../../static/image7.jpg";
import image8 from "../../static/image8.jpg";
import image9 from "../../static/image9.jpg";
import image10 from "../../static/oefentherapie-1.jpg";
import warre from "../../static/warre.jpg";
import image12 from "../../static/image12.jpg";
import visie1 from "../../static/visie-1.jpg";
import visie2 from "../../static/visie-2.jpg";
import visie3 from "../../static/visie-3.jpg";

export default function Home({ location }) {
  const url = location.pathname ? location.pathname : "";

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kine-Osteo Hagers</title>
        <meta
          name="description"
          content="Welkom op de website van Kinesitherapie en Osteopathiepraktijk Hagers in Blankenberge. Gespecialiseerd in o.a. kinderostheopathie"
        />
      </Helmet>
      <Layout routeUrl={url}>
        <Hero />
        <InfoBlockLarge
          title="Over ons"
          desc="Hedwig startte zijn praktijk op samen met zijn echtgenote Christine in april 1983.
          In 1996 werd de praktijk uitgebreid met meerdere lokalen tot een groepspraktijk. Momenteel bestaat het team Hagers uit 3 kinesisten. We werken ook samen met osteopate Lien Hagers."
          image={image9}
          image2={image1}
          image3={image12}
          image4={warre}
          buttonClassName="button--primary"
          buttonText="Onze therapeuten"
          buttonLink="/therapeuten"
        />
        <InfoBlock
          altClass=" section--light"
          title="Visie"
          desc={[
            <p>
              Kinesitherapie betekent letterlijk 'therapie door beweging'. De
              kinesitherapeut(e) voert bepaalde technieken uit (mobilisaties,
              rekkingen, massagetechnieken, elektrotherapie…), stippelt een
              oefenprogramma uit en begeleidt de patiënt(e) om zo het lichaam te
              herstellen.
            </p>,
            <p>
              Elke behandeling is individueel en wordt aangepast aan de noden
              van de patiënt. We werken samen als een team: we overleggen
              geregeld om de algemene werking van de praktijk te optimaliseren.
              We verwijzen ook patiënten binnen de praktijk naar elkaar door,
              zodat elke patiënt behandeld wordt binnen de specialisatie die
              voor hem/haar het meest aangewezen is.
            </p>,
          ]}
          image={visie1}
          image2={visie2}
          image3={visie3}
          buttonClassName="button--secondary"
          buttonText="Contacteer ons"
          buttonLink="/contact"
        />
        <InfoBlock
          title="Specialisaties"
          image={image3}
          image2={image10}
          image3={image8}
          buttonText="Bekijk onze specialisaties"
          buttonLink="/specialisaties"
        />
      </Layout>
    </div>
  );
}
