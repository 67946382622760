import React from "react";
import { Link } from "gatsby";

const InfoBlock = (props) => {
  return (
    <section className={`section${props.altClass ? props.altClass : ""}`}>
      <div className="container">
        <div className="info-block">
          <h2 className="info-block__title">
            <Link to={props.buttonLink}>{props.title}</Link>
          </h2>
          <p className="info-block__desc">{props.desc}</p>
          <div className="info-block__images">
            <div className="grid">
              <div className="grid__item medium--one-third">
                <div className="visual visual--round">
                  <img src={props.image} alt="" />
                </div>
              </div>
              <div className="grid__item medium--one-third">
                <div className="visual visual--round">
                  <img src={props.image2} alt="" />
                </div>
              </div>
              <div className="grid__item medium--one-third">
                <div className="visual visual--round">
                  <img src={props.image3} alt="" />
                </div>
              </div>
            </div>
          </div>
          {props.buttonLink ? (
            <Link
              to={props.buttonLink ? props.buttonLink : ""}
              className={`button button--primary info-block__button ${
                props.buttonClassName ? props.buttonClassName : ""
              }`}
            >
              {props.buttonText}
            </Link>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default InfoBlock;
